export function endpointResolver(template, ...params) {
  const splitted = template.split(process.env.REACT_APP_SEPARATOR);
  const resolvedEndpoint = splitted;
  const positions = [];
  for (let i = 0; i < splitted.length; i++) {
    if (splitted[i].includes(process.env.REACT_APP_START_PLACEHOLDER_CHAR)) {
      positions.push(i);
    }
  }
  if (params.length === positions.length) {
    const regex = new RegExp(
      process.env.REACT_APP_PATTERN_PLACEHOLDER_URL,
      "i"
    );
    for (let i = 0; i < params.length; i++) {
      const replacer = resolvedEndpoint[positions[i]].replace(regex, params[i]);
      resolvedEndpoint.splice(positions[i], 1, replacer);
    }
  }
  return resolvedEndpoint.join(process.env.REACT_APP_SEPARATOR);
}

export function endpointResolverAuth(template, ...params) {
  let finalUrl = template;

  params.forEach((el) => {
    finalUrl = finalUrl.replace(el.key, el.value);
  });

  return finalUrl;
}


const templateB2BMap = {
  localization: process.env.REACT_APP_B2B_API_LOCALIZATION,
  completeFamilies: process.env.REACT_APP_B2B_API_COMPLETE_FAMILIES,
  configurationInit: process.env.REACT_APP_B2B_API_CONFIGURATION_INIT,
  bundle: process.env.REACT_APP_B2B_API_BUNDLE,
  accessories: process.env.REACT_APP_B2B_API_ACCESSORIES,
  redirectDealer: process.env.REACT_APP_B2B_REDIRECT_DEALER,
  addAccessory: process.env.REACT_APP_B2B_ADD_ACCESSORY,
  removeAccessory: process.env.REACT_APP_B2B_REMOVE_ACCESSORY,
  configurationFamily: process.env.REACT_APP_B2B_API_PRECONFIGURATIONS,
  quotation: process.env.REACT_APP_B2B_API_QUOTATION,
  quotationBase: process.env.REACT_APP_B2B_API_QUOTATION_BASE,
  currencyFormatter: process.env.REACT_APP_B2B_API_CURRENCY_FORMATTER,
  saveB2b: process.env.REACT_APP_B2B_API_SAVE,
  inventory: process.env.REACT_APP_B2B_API_INVENTORY,
  outfit: process.env.REACT_APP_B2B_API_OUTFIT,
  apparelDetails: process.env.REACT_APP_B2B_API_APPAREL_DETAILS,
  apparels: process.env.REACT_APP_B2B_API_APPARELS,
  printB2B: process.env.REACT_APP_B2B_API_PRINT,
  bundleDetails: process.env.REACT_APP_B2B_API_BUNDLE_DETAILS,
  accessoryDetails: process.env.REACT_APP_B2B_API_ACCESSORY_DETAILS,
  scramblerLink: process.env.REACT_MENU_SCRAMBLER_LINK,
  dealerNotes: process.env.REACT_APP_B2B_API_DEALER_NOTES,
  mtoInit: process.env.REACT_APP_B2B_API_MTO_INIT,
  mtoPatch: process.env.REACT_APP_B2B_API_MTO_PATCH,
  retrieveConfig: process.env.REACT_APP_B2B_API_MTO_RETRIEVE_CONFIG,
  mtoAddApparel: process.env.REACT_APP_B2B_API_MTO_ADD_APPAREL,
  mtoDeleteApparel: process.env.REACT_APP_B2B_API_MTO_DELETE_APPAREL,
  mtoApparels: process.env.REACT_APP_B2B_API_MTO_APPARELS,
  mtoApparelDetails: process.env.REACT_APP_B2B_API_MTO_APPAREL_DETAILS,
  mtoHierarchy: process.env.REACT_APP_B2B_API_MTO_HIERARCHY,
  mtoEnrichment: process.env.REACT_APP_B2B_API_MTO_ENRICHMENT,
  mtoAccessories: process.env.REACT_APP_B2B_API_MTO_ACCESSORIES,
  mtoAccessoriesEnrichment:
    process.env.REACT_APP_B2B_API_MTO_ACCESSORIES_ENRICHMENT,
  mtoUpdateAccessory: process.env.REACT_APP_B2B_API_MTO_UPDATE_ACCESSORIES,
  mtoSection: process.env.REACT_APP_B2B_API_MTO_SECTION,
  mtoAddAdditionalEntry: process.env.REACT_APP_B2B_API_MTO_ADD_ADDITIONAL_ENTRY,
  mtoDelAdditionalEntry: process.env.REACT_APP_B2B_API_MTO_DEL_ADDITIONAL_ENTRY,
  mtoQuotation: process.env.REACT_APP_B2B_API_MTO_QUOTATION,
  mtoTradeIn: process.env.REACT_APP_B2B_API_MTO_TRADEIN,
  mtoFinance: process.env.REACT_APP_B2B_API_MTO_FINANCE,
  mtoPrintB2B: process.env.REACT_APP_B2B_API_MTO_PRINT,
  mtoCheckAddAcc: process.env.REACT_APP_B2B_CHECK_ADD_ACCESSORY,
  mtoCheckRemoveAcc: process.env.REACT_APP_B2B_CHECK_REMOVE_ACCESSORY,
};




export const templateKeyMap = {
  localization: "localization",
  completeFamilies: "completeFamilies",
  configurationInit: "configurationInit",
  bundle: "bundle",
  accessories: "accessories",
  redirectDealer: "redirectDealer",
  addAccessory: "addAccessory",
  removeAccessory: "removeAccessory",
  saveOnMyDucati: "saveOnMyDucati",
  outfit: "outfit",
  apparelDetails: "apparelDetails",
  apparels: "apparels",
  configurationFamily: "configurationFamily",
  quotation: "quotation",
  quotationBase: "quotationBase",
  currencyFormatter: "currencyFormatter",
  saveB2b: "saveB2b",
  inventory: "inventory",
  printB2B: "printB2B",
  bundleDetails: "bundleDetails",
  accessoryDetails: "accessoryDetails",
  scramblerLink: "scramblerLink",
  dealerNotes: "dealerNotes",
  mtoInit: "mtoInit",
  mtoPatch: "mtoPatch",
  contactDealer: "contactDealer",
  retrieveConfig: "retrieveConfig",
  mtoSaveOnMyDucati: "mtoSaveOnMyDucati",
  mtoAddApparel: "mtoAddApparel",
  mtoDeleteApparel: "mtoDeleteApparel",
  mtoApparels: "mtoApparels",
  mtoApparelDetails: "mtoApparelDetails",
  mtoHierarchy: "mtoHierarchy",
  mtoEnrichment: "mtoEnrichment",
  mtoAccessories: "mtoAccessories",
  mtoAccessoriesEnrichment: "mtoAccessoriesEnrichment",
  mtoUpdateAccessory: "mtoUpdateAccessory",
  mtoSection: "mtoSection",
  mtoAddAdditionalEntry: "mtoAddAdditionalEntry",
  mtoDelAdditionalEntry: "mtoDelAdditionalEntry",
  mtoQuotation: "mtoQuotation",
  mtoTradeIn: "mtoTradeIn",
  mtoFinance: "mtoFinance",
  mtoContactDealer: "mtoContactDealer",
  mtoSaveAccount: "mtoSaveAccount",
  mtoPrintB2B: "mtoPrintB2B",
  mtoCheckAddAcc: "mtoCheckAddAcc",
  mtoCheckRemoveAcc: "mtoCheckRemoveAcc",
  sendToEmail: "sendToEmail",
  mtoSendToEmail: "mtoSendToEmail",
  verifyRecaptcha: "verifyRecaptcha",
};

export function retrieveTemplate(key) {
  let template = "";

  
  template = templateB2BMap[key];
  

  

  return template;
}

import React, { createContext, useContext, useReducer } from "react";
import { isEmptyObject } from "../../utils/Utils";

/**
 * This context stores and handles all google tags
 */
const DataLayerContext = createContext(null);

const initDataLayer = window.dataLayer;

export function DataLayerProvider({ children }) {
  const [dataLayerProvider, dispatch] = useReducer(
    dataLayerReducer,
    initDataLayer
  );

  return (
    <DataLayerContext.Provider value={{ dataLayerProvider, dispatch }}>
      {children}
    </DataLayerContext.Provider>
  );
}

export function useDataLayerDispatch() {
  return useContext(DataLayerContext);
}

export const eventName = {
  pageview: "pageview",
  clickFamily: "wbc-family",
  clickModel: "wbc-model",
  clickEquipment: "wbc-equipment",
  clickZoom: "wbc-zoom-activate",
  clickOpenStep: "wbc-list-step",
  detailStep: "wbc-detail-step",
  configurationSaved: "wbc-save-recap",
  clickGoToShop: "wbc-ecommerce",
  clickFindDealer: "wbc-find-a-dealer",
  clickShare: "wbc-share",
  clickHopOn: "wbc-hop-on",
  userInfo: "user_info",
  clickPersonalizeRate: "wbc-financial-info",
  clickAddAccessory: "wbc-add-step",
  defaultAddAccessory: "wbc-def-step",
  clickRemoveAccessory: "wbc-remove-step",
  clickPrint: "wbc-print",
  clickCam: "wbc-cam-activate",
  viewItemList: "view_item_list",
  wbcSlideshowActivate: "wbc-slideshow-activate",
  camSelect: "wbc-cam-select",
  environmentClick: "wbc-environment-activate",
  environmentSelect: "wbc-environment-select",
  NightDayActivate: "wbc-d-n-view-activate",
  View360Active: "wbc-360-activate",
  screenshotActivate: "wbc-screenshot-activate",
};

export const pageType = {
  family: "wbc-select-family",
  model: "wbc-select-model",
  equipment: "wbc-select-equipment",
  edit: "wbc-edit",
  recap: "wbc-recap",
  zoom: "wbc-zoom",
  editorial: "wbc-editorial",
  editorialShare: "wbc-editorial-share",
  galleryApparel: "wbc-apparel",
  selectCountry: "wbc-select-country",
  privacyInformation: "wbc-privacy-information",
  termsOfUse: "wbc-terms-of-use",
  cookiePolicy: "wbc-cookie-policy",
};

export const pageTags = {
  configurator: "s_configurator",
};

export const virtualPageSuffixType = {
  zoom: "zoom", // da confermare
  selectCountry: "select-country", // da confermare
};

export const WBCModelTags = {
  brand: "DUCATI",
  itemCategory: "moto model",
  quantity: "1",
};

export const WBCDefStepTags = {
  panelVariantElementID: "panelVariantElementID",
};

export const wbcSlideShowTags = {
  eventCategory: "Zoom",
  eventAction: "Zoom Button Click",
};

function getSteps() {
  let result = {};
  
  
  result = {
    accessories: {
      name: "accessories",
      position: 1,
    },
    apparel: {
      name: "apparel",
      position: 2,
    },
  };
  
  return result;
}

export const steps = getSteps();

function dataLayerReducer(dataLayerProvider, action) {
  if (!isEmptyObject(action)) {
    dataLayerProvider.push({ items: null, value: null });
    dataLayerProvider.push(action);
    if (process.env.SENTRY_ENV !== "production") {
      console.debug("dataLayerProvider: ", dataLayerProvider);
    }
  } else {
    console.error("The DataLayerEvent is empty!");
  }
  return dataLayerProvider;
}
